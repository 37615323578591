function slider() {
    $('.reviews-list').slick({
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    });
}

module.exports = slider;