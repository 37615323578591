function timer() {
const timerElement = document.querySelector('.timer');
    const countdownTime = 10 * 60 * 1000; // 10 минут в миллисекундах
    let endTime;

    function formatTime(milliseconds) {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    function updateTimer() {
        const currentTime = new Date().getTime();
        const remainingTime = endTime - currentTime;

        if (remainingTime <= 0) {
            timerElement.textContent = '0:00:00';
            localStorage.removeItem('timerEndTime');
        } else {
            timerElement.textContent = formatTime(remainingTime);
        }
    }

    if (localStorage.getItem('timerEndTime')) {
        endTime = parseInt(localStorage.getItem('timerEndTime'), 10);
    } else {
        endTime = new Date().getTime() + countdownTime;
        localStorage.setItem('timerEndTime', endTime);
    }

    updateTimer();
    setInterval(() => {
        updateTimer()
    }, 1000)
}

module.exports = timer;